import Image from 'next/image';

import RequestDemoButton from '@/components/RequestDemoButton';
import { cn } from '@/utils/theming';

export default function HandwrittenTouch({
  className,
}: {
  className?: string;
}) {
  return (
    <div
      className={cn(
        'grid grid-cols-1 md:grid-cols-2 bg-blue-25 md:rounded-[20px] overflow-hidden',
        className
      )}
    >
      <div className="row-span-1 px-6 pb-0 pt-20 md:pb-20 md:pl-20 md:pr-0">
        <h3 className="text-3xl font-medium">A Handwritten Touch</h3>
        <p className="mt-6 text-lg text-gray-700 lg:font-medium">
          A Handwritten Touch In an age of digital overload, a handwritten note
          stands out. Lebra facilitates this personal touch by allowing leaders
          to craft and send notes with a human feel, directly from the platform.
          <br />
          <br />
          Whether it&apos;s a word of thanks, recognition, or encouragement,
          Lebra helps make it memorable.
        </p>
        <RequestDemoButton className="mt-8 px-10" />
      </div>
      <div className="relative h-[300px]">
        <div className="absolute inset-x-0 mx-auto aspect-square max-w-[500px] md:w-[700px] md:max-w-none lg:translate-y-[-5%]">
          <Image
            src="/images/landing_v2/handwritten-touch.png"
            alt="Handwritten Touch"
            fill
            className="object-cover"
          />
        </div>
      </div>
    </div>
  );
}
