import dynamic from 'next/dynamic';

import BentoBox from '@/features/landing/BentoBox';
import CompanyLogos from '@/features/landing/CompanyLogos';
import HandwrittenTouch from '@/features/landing/HandwrittenTouch';
import Platform from '@/features/landing/Platform';
import Solutions from '@/features/landing/Solutions';
import { Meta } from '@/layouts/Meta';
import { Landing } from '@/templates/LandingV2';

const Hero = dynamic(() => import('@/features/landing/Hero'), {
  ssr: false,
});

const Index = () => {
  return (
    <Landing
      showGetStarted
      meta={
        <Meta
          title="Lebra"
          description="Unite and empower your healthcare team"
        />
      }
    >
      {/* Container div is used to prevent flash of client-side component */}
      <div className="min-h-[550px]">
        <Hero />
      </div>
      <CompanyLogos className="mt-10" />
      <Solutions className="mt-12" />
      <Platform className="mt-20" />
      <HandwrittenTouch className="mt-20" />
      <BentoBox className="mt-20" />
      {/* TODO: Insert testimonial section */}
    </Landing>
  );
};

export default Index;
