function StethoscopeIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8 4.80644C11.9162 4.80644 11.2 5.52222 11.2 6.3829C11.2 7.05346 11.614 7.62583 12.2 7.86317V10.2029C12.2 11.8558 10.8542 13.2009 9.19998 13.2009C7.59298 13.2009 6.28748 11.9285 6.21498 10.3403C8.12748 10.043 9.59998 8.39907 9.59998 6.3829V3.03511C9.59998 2.80791 9.47145 2.60015 9.26823 2.49872L7.56823 1.64928C7.27248 1.52419 6.91248 1.64363 6.76248 1.94034C6.61498 2.23614 6.73498 2.59615 7.03248 2.74456L8.39998 3.42835V6.40538C8.39998 7.94811 7.14373 9.20353 5.59998 9.20353C4.05623 9.20353 2.79998 7.94936 2.79998 6.40538V3.42835L4.16823 2.74431C4.46498 2.59615 4.58498 2.23614 4.43748 1.94034C4.28998 1.64363 3.92998 1.52419 3.63248 1.67174L1.93248 2.52118C1.72848 2.62264 1.59998 2.83025 1.59998 3.03511V6.3829C1.59998 8.38657 3.08773 10.0355 5.01498 10.3203C5.08998 12.5913 6.93248 14.4001 9.19998 14.4001C11.516 14.4001 13.4 12.5168 13.4 10.2029V7.8869C13.985 7.64956 14.4 7.07494 14.4 6.40538C14.4 5.52347 13.6825 4.80644 12.8 4.80644ZM12.8 6.80512C12.5794 6.80512 12.4 6.62579 12.4 6.40538C12.4 6.18498 12.5794 6.00565 12.8 6.00565C13.0205 6.00565 13.2 6.18498 13.2 6.40538C13.2 6.62579 13.02 6.80512 12.8 6.80512Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default StethoscopeIcon;
