import Link from 'next/link';
import { useRouter } from 'next/router';
import type { PropsWithChildren } from 'react';

import RequestDemoButton from '@/components/RequestDemoButton';
import { cn } from '@/utils/theming';

export default function Header() {
  return (
    <header className="fixed inset-x-0 top-0 z-20 bg-white/70 backdrop-blur-md">
      <nav className="flex h-16 items-center border-b border-gray-200 border-b-gray-100 lg:h-20">
        <div className="mx-auto flex w-full  max-w-screen-xl flex-wrap items-center justify-between px-4">
          <div className="flex items-center space-x-8">
            <Link href="/" className="flex items-center">
              <img
                src="/images/logo.svg"
                className="h-[20px] lg:h-[30px]"
                alt="Lebra Logo"
              />
            </Link>
            <HeaderNavigationItem href="/pricing" className="hidden lg:block">
              Pricing
            </HeaderNavigationItem>
          </div>
          <div>
            <div className="flex items-center space-x-2 lg:order-2 lg:space-x-3">
              <Link href="/auth/login">
                <button className="h-8 rounded-lg border-none px-3 text-sm font-medium text-gray-700 transition hover:bg-gray-50 hover:text-blue-700 focus:outline-none focus:ring-4 focus:ring-gray-300 lg:h-10 lg:text-base">
                  Sign in
                </button>
              </Link>
              <RequestDemoButton
                showArrow={false}
                className="h-8 text-sm lg:h-10 lg:text-base"
              />
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}

function HeaderNavigationItem({
  className,
  href,
  children,
}: PropsWithChildren<{ className?: string; href: string }>) {
  const router = useRouter();
  const isActive = router.pathname === href;

  return (
    <Link href={href} className={className}>
      <button
        className={cn(
          'h-8 rounded-lg border-none px-3 text-base font-medium text-gray-700 transition hover:bg-gray-50 hover:text-blue-700 focus:outline-none focus:ring-4 focus:ring-gray-300 lg:h-10',
          {
            'bg-blue-50 text-blue-500': isActive,
          }
        )}
      >
        {children}
      </button>
    </Link>
  );
}
