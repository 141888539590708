import Link from 'next/link';
import type { ComponentProps, PropsWithChildren } from 'react';

import { cn } from '@/utils/theming';

interface RequestDemoButtonProps extends ComponentProps<'a'> {
  showArrow?: boolean;
}

function RequestDemoButton({
  showArrow = false,
  className,
}: PropsWithChildren<RequestDemoButtonProps>) {
  return (
    <Link
      href="/demo"
      className={cn(
        'inline-flex h-10 items-center rounded-lg bg-blue-700 px-3 text-center text-base font-medium text-white hover:bg-blue-800 hover:text-white focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900',
        className
      )}
    >
      Request a Demo
      {showArrow && (
        <svg
          className="-mr-1 ml-2 h-5 w-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
      )}
    </Link>
  );
}

export default RequestDemoButton;
