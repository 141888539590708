import {
  AcademicCapIcon,
  BuildingLibraryIcon,
} from '@heroicons/react/24/outline';
import Image from 'next/image';
import type { ComponentProps, PropsWithChildren } from 'react';
import { useEffect, useState } from 'react';

import StethoscopeIcon from '@/components/CustomIcons/StethoscopeIcon';
import RequestDemoButton from '@/components/RequestDemoButton';
import { cn } from '@/utils/theming';

import Fade from '../Fade';
import styles from './styles.module.css';

export default function Solutions({ className }: { className?: string }) {
  const [active, setActive] = useState(0);

  return (
    <div
      className={cn(
        'px-4 pt-12 lg:p-20 bg-gradient-to-tl from-[#041547] to-[#1C64F2] lg:rounded-[20px] flex flex-col items-center',
        className
      )}
    >
      <div className="space-y-4 text-center">
        <p className="text-xs font-medium uppercase tracking-wide text-blue-400">
          Our solution
        </p>
        <h2 className="px-20 text-3xl font-medium text-white lg:text-4xl">
          Foster Connected Leadership
        </h2>
        <p className="text-lg text-blue-50 lg:font-medium">
          Lebra serves as your central hub for engaged leadership. With seamless
          integrations and intuitive design, Lebra empowers you to ensure every
          employee feels valued and heard.
        </p>
      </div>

      <ShowcaseToggles activeIndex={active} setActiveIndex={setActive} />

      <div className="relative w-full pt-9 lg:h-[480px] lg:pt-0">
        <ShowcaseItem
          title="Empowering Educational Leaders"
          isVisible={active === 0}
          imagePath="/images/landing_v2/solutions-schools.png"
        >
          In the world of education, connection and culture are key. Lebra for
          schools brings administrators, principals, and teachers a tailored
          solution for building a supportive academic culture.
          <br />
          <br />
          With tools for AI-powered teacher evaluation and personalized
          communication, Lebra strengthens the fabric of educational
          institutions and promotes a culture of inclusivity and academic
          excellence.
        </ShowcaseItem>
        <ShowcaseItem
          title="Streamlining Healthcare Leadership"
          isVisible={active === 1}
          imagePath="/images/landing_v2/solutions-hospitals.png"
        >
          Healthcare demands precision, empathy, and timely decision-making.
          Lebra for Hospitals offers an all-in-one place that allows for
          healthcare leaders to engage meaningfully with employees, provide
          recognition, and reduce churn.
          <br />
          <br />
          By providing healthcare leaders with tools that provide visibility of
          who and what to talk about, Lebra helps hospitals foster a culture of
          authentic care and connection.
        </ShowcaseItem>
        <ShowcaseItem
          title="Fostering Civic Culture"
          isVisible={active === 2}
          imagePath="/images/landing_v2/solutions-cities.png"
        >
          Cities are complex, living entities that require responsive and
          adaptive leadership. Lebra for Cities provides municipal leaders with
          the tools to connect with communities, manage city staff, and engage
          stakeholders effectively.
          <br />
          <br />
          With Lebra, city leaders can harness the Lebra web and mobile
          platforms to promote thoughtful staff communication and build a more
          connected culture.
        </ShowcaseItem>
      </div>
    </div>
  );
}

function ShowcaseToggleButton({
  id,
  children,
  active,
  handleToggle,
}: PropsWithChildren<{
  id: string;
  active: boolean;
  handleToggle: () => void;
}>) {
  return (
    <button
      id={id}
      onClick={handleToggle}
      className={cn(
        'rounded-lg px-3 z-20 py-2 text-sm text-blue-200 hover:text-white transition-all duration-300 ease-in-out',
        {
          'text-white': active,
        }
      )}
    >
      {children}
    </button>
  );
}

function ShowcaseToggles({
  activeIndex,
  setActiveIndex,
}: {
  activeIndex: number;
  setActiveIndex: (index: number) => void;
}) {
  function getElementDimensionsById(id: string) {
    const element = document.getElementById(id);
    if (element) {
      return {
        x: element.offsetLeft,
        width: element.clientWidth,
      };
    }

    return null;
  }

  const [toggleIndicatorWidth, setToggleIndicatorWidth] = useState({
    x: 0,
    width: 0,
  });

  useEffect(() => {
    if (typeof window === 'undefined') return;

    // set the initial indicator position
    const dimensions = getElementDimensionsById('schools-solution-toggle');
    if (dimensions) {
      setToggleIndicatorWidth({
        x: dimensions.x,
        width: dimensions.width,
      });
    }
  }, []);

  const handleToggle = (index: number, elementId: string) => {
    setActiveIndex(index);

    // get coordinates of element, to set the indicator in the right position
    const dimensions = getElementDimensionsById(elementId);
    if (dimensions) {
      setToggleIndicatorWidth({
        x: dimensions.x,
        width: dimensions.width,
      });
    }
  };

  return (
    <div
      id="solutions-toggle"
      className={cn(
        'relative mt-8 rounded-xl bg-gradient-to-br from-white/40 to-white/10 p-2.5 space-x-3',
        styles['solutions-toggle-container']
      )}
    >
      <ShowcaseToggleButton
        id="schools-solution-toggle"
        active={activeIndex === 0}
        handleToggle={() => handleToggle(0, 'schools-solution-toggle')}
      >
        <AcademicCapIcon className="mr-2 inline-block h-4 w-4" />
        Schools
      </ShowcaseToggleButton>
      <ShowcaseToggleButton
        id="hospitals-solution-toggle"
        active={activeIndex === 1}
        handleToggle={() => handleToggle(1, 'hospitals-solution-toggle')}
      >
        <StethoscopeIcon className="mr-2 inline-block h-4" />
        Hospitals
      </ShowcaseToggleButton>
      <ShowcaseToggleButton
        id="cities-solution-toggle"
        active={activeIndex === 2}
        handleToggle={() => handleToggle(2, 'cities-solution-toggle')}
      >
        <BuildingLibraryIcon className="mr-2 inline-block h-4 w-4" />
        Cities
      </ShowcaseToggleButton>

      {/* Active indicator */}
      <div
        className="absolute inset-y-2.5 z-[-1] rounded-lg bg-blue-700 transition-all duration-500 ease-in-out"
        style={{
          // set the indicator width and position based on the active toggle
          width: toggleIndicatorWidth.width,
          transform: `translateX(${toggleIndicatorWidth.x - 20}px)`,
        }}
      />
    </div>
  );
}

function ShowcaseItem({
  title,
  children,
  isVisible,
  imagePath,
  className,
  ...restProps
}: ComponentProps<'div'> &
  PropsWithChildren<{
    title: string;
    isVisible: boolean;
    imagePath: string;
  }>) {
  return (
    <Fade
      {...restProps}
      visible={isVisible}
      className={cn('lg:flex items-center lg:absolute', className, {
        hidden: !isVisible,
      })}
    >
      <div className="flex-1 space-y-6">
        <h3 className="text-2xl font-medium text-white lg:text-3xl">{title}</h3>
        <p className="text-base text-blue-50 lg:text-lg lg:font-medium">
          {children}
        </p>
        <RequestDemoButton
          showArrow
          className="border border-gray-100 bg-transparent px-7"
        />
      </div>
      <div className="flex-1">
        <div className="relative h-[260px] lg:h-[480px]">
          <Image src={imagePath} alt={title} fill className="object-contain" />
        </div>
      </div>
    </Fade>
  );
}
