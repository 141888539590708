import type { ComponentPropsWithoutRef, PropsWithChildren } from 'react';
import { useRef } from 'react';
import { Transition } from 'react-transition-group';

import { cn } from '@/utils/theming';

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

export default function Fade({
  visible,
  children,
  ...restProps
}: ComponentPropsWithoutRef<'div'> & PropsWithChildren<{ visible: boolean }>) {
  const nodeRef = useRef(null);
  return (
    <Transition nodeRef={nodeRef} in={visible} timeout={300}>
      {(state) => (
        <div
          ref={nodeRef}
          {...restProps}
          className={cn(
            'transition-opacity duration-300 ease-in-out',
            restProps.className
          )}
          style={transitionStyles[state]}
        >
          {children}
        </div>
      )}
    </Transition>
  );
}
