import Image from 'next/image';
import type { PropsWithChildren } from 'react';

import RequestDemoButton from '@/components/RequestDemoButton';
import { cn } from '@/utils/theming';

export default function BentoBox({ className }: { className?: string }) {
  return (
    <section className={cn('', className)}>
      {/* Heading */}
      <div className="space-y-4 text-center">
        <h3 className="text-4xl font-medium text-gray-900">
          An Essential Tool for Modern Leadership
        </h3>
        <p className="text-lg font-medium text-gray-700">
          Elevate your organization’s leadership with unmatched visibility,
          efficiency, and security.
        </p>
      </div>

      {/* Boxes */}
      <div className="mt-12 flex flex-col gap-4 px-4 lg:grid lg:grid-cols-5 lg:gap-6 lg:px-0">
        {/* Security and privacy */}
        <Box className="bg-gradient-to-br lg:col-span-3">
          <div className="flex-1 space-y-4 p-6 lg:p-8">
            <h5 className="text-xl font-semibold text-white">
              Our Commitment to Data Security and Privacy
            </h5>
            <p className="text-base text-blue-50">
              Trust in Lebra&apos;s commitment to security. With end-to-end
              encryption and, your sensitive information remains protected and
              never disclosed to third parties.
            </p>
            <button className="h-10 w-full rounded-lg border border-white px-3 text-base font-medium text-white transition-all hover:bg-black/30 lg:w-auto">
              More about Lebra’s Security Practices
            </button>
          </div>
          <div className="relative w-full lg:h-[full] lg:w-[306px]">
            <div className="relative inset-x-0 mx-auto aspect-square max-h-[260px] w-[312px] lg:h-[450px] lg:max-h-none lg:w-auto">
              <Image
                src="/images/landing_v2/bento-one.png"
                fill
                alt="Lebra’s commitment to data security and privacy"
                className="object-cover object-center transition-all duration-500 ease-in-out group-hover:scale-105 lg:translate-x-[-10%] lg:object-cover lg:object-left"
              />
            </div>
          </div>
        </Box>

        {/* Culture of recognition */}
        <Box className="relative col-span-2 bg-gradient-to-tl">
          <div className="z-10 flex-1 space-y-4 p-8 text-center">
            <div className="relative inset-x-0 mx-auto h-[100px] w-[260px]">
              <Image
                src="/images/landing_v2/bento-two.png"
                className="object-contain transition-all duration-500 ease-in-out group-hover:scale-105"
                alt="A Culture of Recognition"
                fill
              />
            </div>
            <h5 className="text-xl font-semibold text-white">
              A Culture of Recognition
            </h5>
            <p className="text-base text-blue-50">
              Celebrate successes and milestones with Lebra&apos;s
              <br />
              recognition tools. Acknowledge your successes,
              <br />
              motivating your team and strengthening your culture.
            </p>
            <RequestDemoButton className="h-10 rounded-lg border border-white bg-transparent px-10 text-base font-medium text-white transition-all hover:bg-black/30 lg:px-3" />
          </div>

          {/* Rings */}
          <div className="absolute left-1/2 top-1/4 aspect-square w-[395px] opacity-30">
            {[
              'w-[395px] bg-blue-900',
              'w-[283px] bg-blue-800',
              'w-[174px] bg-blue-900',
            ].map((style, index) => (
              <div
                key={index}
                className={cn(
                  'absolute left-1/2 top-1/2 aspect-square -translate-x-1/2 -translate-y-1/2 rounded-full',
                  style
                )}
              />
            ))}
          </div>
        </Box>

        {/* Take Lebra on the Go */}
        <Box className="relative col-span-2 bg-gradient-to-l">
          <div className="z-10 flex-1 space-y-4 p-8 text-center">
            <div className="relative inset-x-0 mx-auto h-[82px] w-[188px]">
              <Image
                src="/images/landing_v2/bento-three.png"
                className="object-contain transition-all duration-500 ease-in-out group-hover:scale-105"
                alt="Lebra Mobile App"
                fill
              />
            </div>
            <h5 className="text-xl font-semibold text-white">
              Take Lebra on the Go
            </h5>
            <p className="text-base text-blue-50">
              Lebra&apos;s app keeps you in sync with team milestones and
              <br />
              personal notes — celebrate, jot down, and remember
              <br />
              where you left off effortlessly. All on your phone.
            </p>
            <RequestDemoButton className="h-10 rounded-lg border border-white bg-transparent px-10 text-base font-medium text-white transition-all hover:bg-black/30 lg:px-3" />
          </div>

          {/* Rings */}
          <div className="absolute right-1/2 top-1/4 aspect-square w-[395px]">
            {['w-[395px]', 'w-[283px]', 'w-[174px]'].map((size, index) => (
              <div
                key={index}
                className={cn(
                  'absolute left-1/2 top-1/2 aspect-square -translate-x-1/2 -translate-y-1/2 rounded-full border-[0.5px] border-blue-800',
                  size
                )}
              />
            ))}
          </div>
        </Box>

        {/* Streamlined Data Integrations */}
        <Box className="col-span-3 bg-gradient-to-tl">
          <div className="flex-1 space-y-4 p-8">
            <h5 className="text-xl font-semibold text-white">
              Streamlined Data Integrations
            </h5>
            <p className="text-base text-blue-50">
              Lebra synchronizes with your HRIS, email, and calendar systems,
              serving as a central hub. By consolidating critical data points,
              Lebra provides a clear timeline of interactions with each staff
              member.
              <br />
              <br />
              Ensure you&apos;re always informed and up-to-date. Make
              well-informed decisions and maintain a consistent and supportive
              presence for your team.
            </p>
            <button className="h-10 rounded-lg border border-white px-10 text-base font-medium text-white transition-all hover:bg-black/30 lg:px-3">
              Read more
            </button>
          </div>
          <div className="relative aspect-square w-[170px] lg:h-full lg:w-[306px]">
            <Image
              src="/images/landing_v2/bento-four.png"
              fill
              alt="Streamlined Data Integrations"
              className="object-cover transition-all duration-500 ease-in-out group-hover:scale-105"
            />
          </div>
        </Box>
      </div>
    </section>
  );
}

function Box({
  className,
  children,
}: PropsWithChildren<{ className: string }>) {
  return (
    <div
      className={cn(
        'flex flex-col lg:flex-row items-center group lg:h-[348px] rounded-[20px] overflow-hidden from-[#041547] to-[#1C64F2] transition-all duration-500 shadow-md bg-[length:120%_120%] hover:bg-right',
        className
      )}
    >
      {children}
    </div>
  );
}
