import { EyeIcon, LightBulbIcon, UsersIcon } from '@heroicons/react/24/outline';
import Image from 'next/image';

import RequestDemoButton from '@/components/RequestDemoButton';
import { cn } from '@/utils/theming';

const cards = [
  {
    title: 'Reconnect Reminders',
    description:
      'Never miss a beat – Lebra reconnect reminders help you re-engage and ensure regular check-ins with reports across your organizations.',
    icon: <UsersIcon className="h-6 w-6 text-white" />,
  },
  {
    title: 'AI Assist',
    description:
      'Lebra is your AI-powered  co-author for crafting emails and agendas that resonate. Save time and inspire action with communications that count.',
    icon: <LightBulbIcon className="h-6 w-6 text-white" />,
  },
  {
    title: 'A Birds’ Eye View',
    description:
      'Gain perspective Lebra’s interactive org chart, which maps reporting structure and your last last interaction. Stay engaged with the relationships that matter.',
    icon: <EyeIcon className="h-6 w-6 text-white" />,
  },
];

export default function Platform({ className }: { className?: string }) {
  return (
    <div className={cn('overflow-hidden', className)}>
      <div className="absolute inset-x-0 h-[150%] w-full lg:translate-y-[-40%]">
        <Image
          src="/images/landing_v2/gradient-background.png"
          alt="Platform"
          fill
          className="object-fill"
        />
      </div>
      <div className="relative flex flex-col-reverse px-4 lg:flex-row lg:items-center lg:space-x-10 lg:px-10">
        <div className="mt-10 h-[358px] lg:mt-0 lg:h-auto lg:flex-1">
          <div className="relative h-full w-full lg:aspect-square lg:h-auto ">
            <Image
              src={'/images/landing_v2/platform-image.png'}
              fill
              className="object-contain"
              alt="Our platform"
              sizes="(max-width: 1024px) 600px, 358px"
            />
          </div>
        </div>
        <div className="flex-1 space-y-4">
          <p className="text-xs font-medium uppercase tracking-wide text-blue-400">
            Our platform
          </p>
          <h2 className="text-3xl font-medium text-gray-900 lg:text-4xl">
            Harnessing AI for
            <br />
            Empathetic Leadership
          </h2>
          <p className="text-lg text-gray-700 lg:font-medium">
            At Lebra, we blend cutting-edge AI with an understanding of the
            human touch. Our platform assists leaders in managing workforce
            relationships while fostering a culture that values each employee.
          </p>
          <RequestDemoButton
            showArrow
            className="rounded-lg border border-blue-400 bg-white px-7 text-base font-medium text-blue-600 transition-all duration-300 hover:border-0 hover:bg-blue-600 hover:text-white"
          />
        </div>
      </div>

      {/* Cards */}
      <div className="mt-[50px] flex flex-col gap-6 px-4 lg:grid lg:grid-cols-3 lg:px-0">
        {cards.map((card, index) => (
          <div
            key={index}
            className="z-10 rounded-[20px] border-4 border-blue-50 bg-white p-6"
          >
            <div className="flex h-12 w-12 items-center justify-center rounded-xl bg-gradient-to-r from-blue-600 to-blue-900">
              {card.icon}
            </div>
            <h4 className="mt-4 text-xl font-semibold text-gray-900">
              {card.title}
            </h4>
            <p className="mt-2 text-base tracking-tight text-gray-600 ">
              {card.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
