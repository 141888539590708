import type { ReactNode } from 'react';

import Footer from '@/features/landing/Footer';
import GetStarted from '@/features/landing/GetStarted';
import Header from '@/features/landing/Header';

type IMainProps = {
  meta: ReactNode;
  children: ReactNode;
  showHeader?: boolean;
  showGetStarted?: boolean;
  className?: string;
};

const Landing = (props: IMainProps) => (
  // eslint-disable-next-line tailwindcss/no-custom-classname
  <div
    className={`absolute h-full w-full overflow-y-auto text-gray-700 antialiased dark:text-gray-100 ${props.className}`}
  >
    <Header />
    {props.meta}
    <main className="mx-auto mt-20 max-w-[1200px] dark:bg-gray-1000">
      {props.children}
    </main>
    {props.showGetStarted && <GetStarted />}
    <Footer />
  </div>
);

export { Landing };
