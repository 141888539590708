import Image from 'next/image';

import RequestDemoButton from '@/components/RequestDemoButton';
import { cn } from '@/utils/theming';

export default function GetStarted({ className }: { className?: string }) {
  return (
    <section className={cn('bg-white', className)}>
      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
          <Image
            src="/images/landing_v2/get-started-icon.png"
            alt="Lebra"
            height={204}
            width={168}
            className="mx-auto mb-6"
          />
          <h2 className="mb-4 text-4xl font-medium text-gray-900">
            Get started with Lebra today
          </h2>
          <p className="mb-6 text-lg font-medium text-gray-500">
            Join us on a journey of leadership transformation. Connect deeper,
            lead better, and make a lasting impact.
          </p>
          <RequestDemoButton className="px-10" />
        </div>
      </div>
    </section>
  );
}
