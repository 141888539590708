import Link from 'next/link';

export default function Footer() {
  return (
    <footer className="mx-6 mb-6 rounded-[20px] bg-gradient-to-b from-transparent to-blue-50 pt-20">
      <div className="mx-auto grid max-w-7xl grid-cols-2 gap-6 px-4 pb-8 pt-10 lg:grid-cols-5 lg:gap-0 lg:px-0">
        <Link href="/" className="col-span-2 mb-8 lg:col-span-1 lg:mb-0">
          <img src="/images/logo.svg" className="h-7" alt="Lebra Logo" />
        </Link>
        {/* <FooterColumn
          title="Platform"
          items={[
            {
              text: 'Reconnect',
              href: '#',
            },
            {
              text: 'Powered by AI',
              href: '#',
            },
            {
              text: 'Org Chart',
              href: '#',
            },
            {
              text: 'Blog',
              href: '/blog',
            },
          ]}
        />
        <FooterColumn
          title="Solutions"
          items={[
            {
              text: 'C-Suite',
              href: '#',
            },
            {
              text: 'People and HR',
              href: '#',
            },
            {
              text: 'Hospitals',
              href: '#',
            },
            {
              text: 'Cities',
              href: '/blog',
            },
            {
              text: 'Schools',
              href: '#',
            },
            {
              text: 'Businesses',
              href: '#',
            },
          ]}
        />
        */}
        <FooterColumn
          title="Company"
          items={[
            {
              text: 'Pricing',
              href: '/pricing',
            },
            /* 
            {
              text: 'Testimonials',
              href: '#',
            },
            {
              text: 'Contact',
              href: '#',
            }, */
            {
              text: 'Terms of Service',
              href: '/termsofservice',
            },
            {
              text: 'Privacy Policy',
              href: '/privacypolicy',
            },
          ]}
        />
        <FooterColumn
          title="Policies and Information"
          items={[
            {
              text: 'Security and Privacy',
              href: '/security',
            },
          ]}
        />
      </div>
      <div className="mx-[120px] h-[1px] bg-gradient-to-r from-transparent via-blue-100 to-transparent" />
      <div className="block self-center py-8 text-center text-xs text-gray-500 dark:text-gray-400">
        {new Date().getFullYear()} © Lebra Technologies Inc. All Rights
        Reserved.
      </div>
    </footer>
  );
}

const FooterColumn = ({
  title,
  items,
}: {
  title: string;
  items: { text: string; href: string }[];
}) => (
  <div className="">
    <p className="text-base font-medium text-gray-900">{title}</p>
    <div className="mt-2 flex flex-col space-y-1">
      {items.map((item) => {
        return (
          <Link
            key={item.text}
            href={item.href}
            className="text-base text-gray-700 hover:text-gray-500 hover:underline"
          >
            {item.text}
          </Link>
        );
      })}
    </div>
  </div>
);
