import React from 'react';

const logos = [
  {
    src: '/images/landing_v2/companies/north-kansas-city-schools.png',
    alt: 'North Kansas City School District',
  },
  {
    src: '/images/landing_v2/companies/magnolia.png',
    alt: 'Magnolia Regional Health Center',
  },
  {
    src: '/images/landing_v2/companies/ksb.png',
    alt: 'Katherine Shaw Bethea Hospital',
  },
  {
    src: '/images/landing_v2/companies/grandview-school-district.png',
    alt: 'Grandview School District Logo',
  },
  {
    src: '/images/landing_v2/companies/hannibal-regional.png',
    alt: 'Hannibal Regional Hospital',
  },
  {
    src: '/images/landing_v2/companies/chillicothe.png',
    alt: 'Chillicothe School District',
  },
];

export default function CompanyLogos({
  className,
  showHeader = true,
}: {
  className?: string;
  showHeader?: boolean;
}) {
  return (
    <div className={className}>
      {showHeader && (
        <h5 className="mb-6 text-center text-base font-medium text-gray-500">
          Trusted by leaders of high-performing organizations
        </h5>
      )}
      <ul className="grid grid-cols-3 gap-5 px-4 py-2 md:flex lg:gap-[60px]">
        {logos.map((logo, index) => (
          <li
            className="flex h-[60px] flex-1 items-center justify-center px-4"
            key={index}
          >
            <img
              src={logo.src}
              alt={logo.alt}
              className="h-full object-contain"
            />
          </li>
        ))}
      </ul>
    </div>
  );
}
